import {
  SoftOfferOptionsKeys,
  SoftOfferSecurityDeposit,
  SoftOfferSecurityDepositFields,
  SoftOfferPayment,
  SoftOfferPaymentFields,
  OlistItemOption,
  OlistItemOptionFields,
} from 'types/vendor';
import { mapToSelectOptions } from 'utils/formField/mapToSelectOptions';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import FormField from 'components/form/formik/FormField';
import Select from 'components/form/Selector';
import { Status } from 'utils/types';
import { useSelector } from 'react-redux';
import { softOffersSelectors } from 'store';
import { useTranslation } from 'react-i18next';
import { FormikProvider, useFormik, Form } from 'formik';
import { getSchema } from './schema';
import Footer from '../Footer';
import { Col, Row } from 'antd';
import Checkbox from 'components/form/Checkbox';
import { ScCheckboxFormFieldWrapper, ScCollapse, ScCollapsePanel } from '../styles';
import Switcher from 'components/form/Switcher';
import { isFieldRequired } from 'utils/is-field-required';
import { omit } from 'utils';
import {
  SOFT_OFFER_GUARANTEE_FORMULA_TO_DEDUCTIBLE_FORMULA_MAPPING,
  TEMP__SOFT_OFFER_PAYMENT_OPTIONS,
} from 'common/constants';
import { InputNumberLocalized } from 'components/form/InputNumberLocalized';

const SUB_ROW_SPACES: [number, number] = [16, 0];

const tPrefix = 'SOFT_OFFER_SECURITY_DEPOSIT_';
const testPrefix = 'soft-offer_security-deposit_';

/** This form combines single field from {@link SoftOfferPayment} and whole {@link SoftOfferSecurityDeposit}
 * so added payment field under separate object {@link SoftOfferPaymentFields.paymentType} */
type FormValues = SoftOfferSecurityDeposit & { payment: SoftOfferPayment };

interface Props {
  isDisabled: boolean;
  initialValues?: SoftOfferSecurityDeposit;
  initialValuesPayment?: SoftOfferPayment;
  onSubmit(values: SoftOfferSecurityDeposit, valuesPayment: SoftOfferPayment): void;
  setIsTabLocked?(value: boolean): void;
}

const SecurityDepositForm: FC<Props> = ({
  onSubmit,
  initialValues,
  initialValuesPayment,
  isDisabled,
  setIsTabLocked,
}) => {
  const { t, i18n } = useTranslation();

  const options = useSelector(softOffersSelectors.getOptions);
  const status = useSelector(softOffersSelectors.getDetailsStatus);

  const isLoading = useMemo<boolean>(() => status === Status.Loading, [status]);

  const omniumTypeList = options?.[SoftOfferOptionsKeys.OmniumType]?.options ?? [];
  const evaluationTypeList =
    options?.[SoftOfferOptionsKeys.EvaluationType]?.options ?? [];
  const selectedPJList = options?.[SoftOfferOptionsKeys.SelectedPJ]?.options ?? [];
  const driverList = options?.[SoftOfferOptionsKeys.Driver]?.options ?? [];
  const guaranteeFormulaTmpList =
    options?.[SoftOfferOptionsKeys.GuaranteeFormula_tmp]?.options ?? [];
  const deductibleFormulasList =
    options?.[SoftOfferOptionsKeys.DeductibleFormula]?.options ?? [];

  const schema = getSchema(t);

  const getActiveTabsKeys = (values: SoftOfferSecurityDeposit): string[] => {
    return [
      SoftOfferSecurityDepositFields.isOmnium,
      SoftOfferSecurityDepositFields.isThirdPartyLiabilityChosen,
      SoftOfferSecurityDepositFields.isDriverChosen,
      SoftOfferSecurityDepositFields.isLegalAssistanceChosen,
    ].filter(key => values?.[key]);
  };

  const handleSubmit = useCallback(
    (values: FormValues) => {
      const securityDepositValues = omit(values, 'payment');
      const paymentValues = values.payment;
      onSubmit(securityDepositValues, paymentValues);
    },
    [onSubmit],
  );

  const assembledInitialValues = useMemo<FormValues>(() => {
    const securityDepositInitialValues = initialValues!;
    const paymentInitialValues = initialValuesPayment!;

    return {
      ...securityDepositInitialValues,
      payment: paymentInitialValues,
    };
  }, [initialValues, initialValuesPayment]);

  const formProps = useFormik<FormValues>({
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: false,
    initialValues: assembledInitialValues,
    validationSchema: schema,
    onSubmit: handleSubmit,
  });

  const { setFieldValue, values, dirty } = formProps;

  useEffect(() => {
    setIsTabLocked?.(dirty);
  }, [dirty, setIsTabLocked]);

  const paymentOptions = useMemo(() => {
    return TEMP__SOFT_OFFER_PAYMENT_OPTIONS.map(item => ({
      ...item,
      label: item.translations[i18n.language],
    }));
  }, [i18n.language]);

  return (
    <FormikProvider value={formProps}>
      <Form data-testid="soft-offer-step-form">
        <Row>
          <Col md={8}>
            <FormField
              id={SoftOfferPaymentFields.paymentType}
              name={`payment.${SoftOfferPaymentFields.paymentType}`}
              component={Select}
              loading={isLoading}
              options={mapToSelectOptions<OlistItemOption>(paymentOptions, {
                label: 'label',
                value: 'value',
              })}
              required={isFieldRequired(
                schema,
                `payment.${SoftOfferPaymentFields.paymentType}`,
              )}
              label={t(tPrefix + 'PAYMENT')}
              data-testid={testPrefix + 'payment'}
              disabled={isDisabled}
            />
          </Col>
        </Row>

        <ScCollapse activeKey={getActiveTabsKeys(values)}>
          <ScCollapsePanel
            showArrow={false}
            key={SoftOfferSecurityDepositFields.isThirdPartyLiabilityChosen}
            header={
              <>
                <FormField
                  name={SoftOfferSecurityDepositFields.isThirdPartyLiabilityChosen}
                  component={Switcher}
                  checked={
                    values?.[SoftOfferSecurityDepositFields.isThirdPartyLiabilityChosen]
                  }
                  onClick={(value, event) => {
                    event.stopPropagation();
                    setFieldValue(
                      SoftOfferSecurityDepositFields.isThirdPartyLiabilityChosen,
                      value,
                    );
                  }}
                  data-testid={testPrefix + 'isThirdPartyLiabilityChosen'}
                  disabled={isDisabled}
                />
                {t(tPrefix + 'IS_THIRD_PARTY_LIABILITY_CHOSEN')}
              </>
            }
          >
            <Row gutter={SUB_ROW_SPACES}>
              <Col sm={8}>
                <FormField
                  id={SoftOfferSecurityDepositFields.vehiclePlaces}
                  name={SoftOfferSecurityDepositFields.vehiclePlaces}
                  component={InputNumberLocalized}
                  // set to true, because isFieldRequired cant get required state from field with conditional require
                  // library doesnt support that
                  required={true}
                  label={t(tPrefix + 'VEHICLE_PLACES')}
                  data-testid={testPrefix + 'vehiclePlaces'}
                  disabled={
                    isDisabled ||
                    !values?.[SoftOfferSecurityDepositFields.isThirdPartyLiabilityChosen]
                  }
                />
              </Col>
              <Col sm={8}>
                <FormField
                  id={SoftOfferSecurityDepositFields.yearsWithoutSinistre}
                  name={SoftOfferSecurityDepositFields.yearsWithoutSinistre}
                  component={InputNumberLocalized}
                  required={isFieldRequired(
                    schema,
                    SoftOfferSecurityDepositFields.yearsWithoutSinistre,
                  )}
                  label={t(tPrefix + 'YEARS_WITHOUT_SINISTRE')}
                  data-testid={testPrefix + 'yearsWithoutSinistre'}
                  disabled={
                    isDisabled ||
                    !values?.[SoftOfferSecurityDepositFields.isThirdPartyLiabilityChosen]
                  }
                />
              </Col>

              <Col sm={8}>
                <FormField
                  id={SoftOfferSecurityDepositFields.claimsNotAtFaultCount}
                  name={SoftOfferSecurityDepositFields.claimsNotAtFaultCount}
                  component={InputNumberLocalized}
                  required={isFieldRequired(
                    schema,
                    SoftOfferSecurityDepositFields.claimsNotAtFaultCount,
                  )}
                  label={t(tPrefix + 'CLAIMS_NOT_AT_FAULT_COUNT')}
                  data-testid={testPrefix + 'claimsNotAtFaultCount'}
                  disabled={
                    isDisabled ||
                    !values?.[SoftOfferSecurityDepositFields.isThirdPartyLiabilityChosen]
                  }
                />
              </Col>
            </Row>
          </ScCollapsePanel>

          <ScCollapsePanel
            showArrow={false}
            key={SoftOfferSecurityDepositFields.isOmnium}
            header={
              <>
                <FormField
                  name={SoftOfferSecurityDepositFields.isOmnium}
                  component={Switcher}
                  checked={values?.[SoftOfferSecurityDepositFields.isOmnium]}
                  onClick={(value, event) => {
                    event.stopPropagation();
                    setFieldValue(SoftOfferSecurityDepositFields.isOmnium, value);
                  }}
                  data-testid={testPrefix + 'isOmnium'}
                  disabled={isDisabled}
                />
                {t(tPrefix + 'IS_OMNIUM')}
              </>
            }
          >
            <Row gutter={SUB_ROW_SPACES}>
              <Col sm={8}>
                <FormField
                  id={SoftOfferSecurityDepositFields.omniumType}
                  name={SoftOfferSecurityDepositFields.omniumType}
                  component={Select}
                  loading={isLoading}
                  options={mapToSelectOptions<OlistItemOption>(omniumTypeList, {
                    label: OlistItemOptionFields.label,
                    value: OlistItemOptionFields.value,
                  })}
                  required={isFieldRequired(
                    schema,
                    SoftOfferSecurityDepositFields.omniumType,
                  )}
                  data-testid={testPrefix + 'omniumType'}
                  label={t(tPrefix + 'OMNIUM_TYPE')}
                  disabled={
                    isDisabled || !values?.[SoftOfferSecurityDepositFields.isOmnium]
                  }
                />
              </Col>
              <Col sm={8}>
                <FormField
                  id={SoftOfferSecurityDepositFields.evaluationType}
                  name={SoftOfferSecurityDepositFields.evaluationType}
                  component={Select}
                  loading={isLoading}
                  options={mapToSelectOptions<OlistItemOption>(evaluationTypeList, {
                    label: OlistItemOptionFields.label,
                    value: OlistItemOptionFields.value,
                  })}
                  required={isFieldRequired(
                    schema,
                    SoftOfferSecurityDepositFields.evaluationType,
                  )}
                  label={t(tPrefix + 'EVALUATION_TYPE')}
                  data-testid={testPrefix + 'evaluationType'}
                  disabled={
                    isDisabled || !values?.[SoftOfferSecurityDepositFields.isOmnium]
                  }
                />
              </Col>
              <Col sm={8}>
                <FormField
                  id={SoftOfferSecurityDepositFields.vehicleValueNetto}
                  name={SoftOfferSecurityDepositFields.vehicleValueNetto}
                  component={InputNumberLocalized}
                  required={isFieldRequired(
                    schema,
                    SoftOfferSecurityDepositFields.vehicleValueNetto,
                  )}
                  label={t(tPrefix + 'VALUE_NETTO')}
                  data-testid={testPrefix + 'vehicleValueNetto'}
                  disabled
                />
              </Col>
              <Col sm={8}>
                <FormField
                  id={SoftOfferSecurityDepositFields.vehicleValueBrutto}
                  name={SoftOfferSecurityDepositFields.vehicleValueBrutto}
                  component={InputNumberLocalized}
                  required={true}
                  label={t(tPrefix + 'VALUE_BRUTTO')}
                  data-testid={testPrefix + 'vehicleValueBrutto'}
                  disabled={
                    isDisabled || !values?.[SoftOfferSecurityDepositFields.isOmnium]
                  }
                />
              </Col>
              <Col sm={8}>
                <FormField
                  id={SoftOfferSecurityDepositFields.invoicePrice}
                  name={SoftOfferSecurityDepositFields.invoicePrice}
                  component={InputNumberLocalized}
                  required={true}
                  label={t(tPrefix + 'INVOICE_PRICE')}
                  data-testid={testPrefix + 'invoicePrice'}
                  disabled={
                    isDisabled || !values?.[SoftOfferSecurityDepositFields.isOmnium]
                  }
                />
              </Col>
              <Col sm={8}>
                <FormField
                  id={SoftOfferSecurityDepositFields.guaranteeFormula}
                  name={SoftOfferSecurityDepositFields.guaranteeFormula}
                  component={Select}
                  loading={isLoading}
                  options={mapToSelectOptions<OlistItemOption>(guaranteeFormulaTmpList, {
                    label: OlistItemOptionFields.label,
                    value: OlistItemOptionFields.value,
                  })}
                  onChange={event => {
                    setFieldValue(
                      SoftOfferSecurityDepositFields.guaranteeFormula,
                      event.target.value,
                    );

                    setFieldValue(
                      SoftOfferSecurityDepositFields.deductibleFormula,
                      SOFT_OFFER_GUARANTEE_FORMULA_TO_DEDUCTIBLE_FORMULA_MAPPING[
                        event.target.value
                      ],
                    );
                  }}
                  required={true}
                  label={t(tPrefix + 'GUARANTEE_FORMULA')}
                  data-testid={testPrefix + 'guaranteeFormula'}
                  disabled={
                    isDisabled || !values?.[SoftOfferSecurityDepositFields.isOmnium]
                  }
                />
              </Col>

              <Col sm={8}>
                <FormField
                  id={SoftOfferSecurityDepositFields.deductibleFormula}
                  name={SoftOfferSecurityDepositFields.deductibleFormula}
                  component={Select}
                  loading={isLoading}
                  options={mapToSelectOptions<OlistItemOption>(deductibleFormulasList, {
                    label: OlistItemOptionFields.label,
                    value: OlistItemOptionFields.value,
                  })}
                  required={isFieldRequired(
                    schema,
                    SoftOfferSecurityDepositFields.deductibleFormula,
                  )}
                  label={t(tPrefix + 'DEDUCTIBLE_FORMULA')}
                  data-testid={testPrefix + 'deductibleFormula'}
                  disabled
                />
              </Col>

              <Col sm={8}>
                <FormField
                  id={SoftOfferSecurityDepositFields.claimsVehicleDamageCount}
                  name={SoftOfferSecurityDepositFields.claimsVehicleDamageCount}
                  component={InputNumberLocalized}
                  required={isFieldRequired(
                    schema,
                    SoftOfferSecurityDepositFields.claimsVehicleDamageCount,
                  )}
                  label={t(tPrefix + 'CLAIMS_VEHICLE_DAMAGE_COUNT')}
                  data-testid={testPrefix + 'claimsVehicleDamageCount'}
                  disabled={
                    isDisabled || !values?.[SoftOfferSecurityDepositFields.isOmnium]
                  }
                />
              </Col>
              <Col sm={8}>
                <FormField
                  id={SoftOfferSecurityDepositFields.theftClaimsCount}
                  name={SoftOfferSecurityDepositFields.theftClaimsCount}
                  component={InputNumberLocalized}
                  required={isFieldRequired(
                    schema,
                    SoftOfferSecurityDepositFields.theftClaimsCount,
                  )}
                  label={t(tPrefix + 'THEFT_CLAIMS_COUNT')}
                  data-testid={testPrefix + 'theftClaimsCount'}
                  disabled={
                    isDisabled || !values?.[SoftOfferSecurityDepositFields.isOmnium]
                  }
                />
              </Col>

              <Col sm={8}>
                <ScCheckboxFormFieldWrapper>
                  <FormField
                    id={SoftOfferSecurityDepositFields.isSafetyPackChosen}
                    name={SoftOfferSecurityDepositFields.isSafetyPackChosen}
                    component={Checkbox}
                    checked={values?.[SoftOfferSecurityDepositFields.isSafetyPackChosen]}
                    required={isFieldRequired(
                      schema,
                      SoftOfferSecurityDepositFields.isSafetyPackChosen,
                    )}
                    label={t(tPrefix + 'IS_SAFETY_PACK_CHOSEN')}
                    data-testid={testPrefix + 'isSafetyPackChosen'}
                    disabled={
                      isDisabled || !values?.[SoftOfferSecurityDepositFields.isOmnium]
                    }
                  />
                </ScCheckboxFormFieldWrapper>
              </Col>
            </Row>
          </ScCollapsePanel>

          <ScCollapsePanel
            showArrow={false}
            key={SoftOfferSecurityDepositFields.isLegalAssistanceChosen}
            header={
              <>
                <FormField
                  name={SoftOfferSecurityDepositFields.isLegalAssistanceChosen}
                  component={Switcher}
                  checked={
                    values?.[SoftOfferSecurityDepositFields.isLegalAssistanceChosen]
                  }
                  onClick={(value, event) => {
                    event.stopPropagation();
                    setFieldValue(
                      SoftOfferSecurityDepositFields.isLegalAssistanceChosen,
                      value,
                    );
                  }}
                  data-testid={testPrefix + 'isLegalAssistanceChosen'}
                  disabled={isDisabled}
                />
                {t(tPrefix + 'IS_LEGAL_ASSISTANCE_CHOSEN')}
              </>
            }
          >
            <Row>
              <Col md={8}>
                <FormField
                  id={SoftOfferSecurityDepositFields.selectedPJ}
                  name={SoftOfferSecurityDepositFields.selectedPJ}
                  component={Select}
                  loading={isLoading}
                  options={mapToSelectOptions<OlistItemOption>(selectedPJList, {
                    label: OlistItemOptionFields.label,
                    value: OlistItemOptionFields.value,
                  })}
                  required={isFieldRequired(
                    schema,
                    SoftOfferSecurityDepositFields.selectedPJ,
                  )}
                  label={t(tPrefix + 'SELECTED_PJ')}
                  data-testid={testPrefix + 'selectedPJ'}
                  disabled={
                    isDisabled ||
                    !values?.[SoftOfferSecurityDepositFields.isLegalAssistanceChosen]
                  }
                />
              </Col>
            </Row>
          </ScCollapsePanel>

          <ScCollapsePanel
            showArrow={false}
            key={SoftOfferSecurityDepositFields.isDriverChosen}
            header={
              <>
                <FormField
                  name={SoftOfferSecurityDepositFields.isDriverChosen}
                  component={Switcher}
                  checked={values?.[SoftOfferSecurityDepositFields.isDriverChosen]}
                  onClick={(value, event) => {
                    event.stopPropagation();
                    setFieldValue(SoftOfferSecurityDepositFields.isDriverChosen, value);
                  }}
                  data-testid={testPrefix + 'isDriverChosen'}
                  disabled={isDisabled}
                />
                {t(tPrefix + 'IS_DRIVER_CHOSEN')}
              </>
            }
          >
            <Row>
              <Col md={8}>
                <FormField
                  id={SoftOfferSecurityDepositFields.driver}
                  name={SoftOfferSecurityDepositFields.driver}
                  component={Select}
                  loading={isLoading}
                  options={mapToSelectOptions<OlistItemOption>(driverList, {
                    label: OlistItemOptionFields.label,
                    value: OlistItemOptionFields.value,
                  })}
                  required={isFieldRequired(
                    schema,
                    SoftOfferSecurityDepositFields.driver,
                  )}
                  label={t(tPrefix + 'DRIVER')}
                  data-testid={testPrefix + 'driver'}
                  disabled={
                    isDisabled || !values?.[SoftOfferSecurityDepositFields.isDriverChosen]
                  }
                />
              </Col>
            </Row>
          </ScCollapsePanel>
        </ScCollapse>

        <Footer isSubmitDisabled={isDisabled} />
      </Form>
    </FormikProvider>
  );
};

export default SecurityDepositForm;
