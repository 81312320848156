import React, { FC, useEffect, useMemo, useState } from 'react';
import { Modal } from 'components/modal';
import {
  StockMachineOption,
  StockMachineOptionFields,
  StockMachineDetails,
  StockMachineDetailsFields,
} from 'types/vendor';
import { useTranslation } from 'react-i18next';
import { get } from 'utils';
import { ScModalContent, ScRotatingLoaderWrap } from './StockOptionsModal.styles';
import { useMachineApi } from 'context/machine/useMachineApi';
import Table from 'components/table';
import useColumns from './use-columns';
import { sortTableDataBySearchValue } from 'utils/format';
import { Input } from 'components/form/Input';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { Col, Row } from 'antd';
import { RotatingLoader } from 'components/RotatingLoader';
import { useFormik, FormikProvider, Form } from 'formik';
import FormField from 'components/form/formik/FormField';
import { isFieldRequired } from 'utils/is-field-required';
import * as Yup from 'yup';
import { DatePicker } from 'components/form/DatePicker';
import { DATE_FORMAT } from 'common/constants';
import { InputNumber } from 'components/form/InputNumber';

interface Props {
  machineCode: string;
  onClose: () => void;
}
export type StockOptionsModalState = Props;
const StockOptionsModal: FC<Props> = ({ onClose, machineCode }) => {
  const { t } = useTranslation();
  const { getStockMachineOptionsList, getStockMachineDetails } = useMachineApi();

  const [details, setDetails] = useState<StockMachineDetails | null>(null);
  const [isLoadingDetails, setIsLoadingDetails] = useState<boolean>(false);

  const [searchValue, setSearchValue] = useState<string>('');
  const [optionsList, setOptionsList] = useState<StockMachineOption[]>([]);
  const [isLoadingOptionsList, setIsLoadingOptionsList] = useState<boolean>(false);

  const preparedOptionsList = useMemo(() => {
    return sortTableDataBySearchValue({ data: optionsList, searchValue: searchValue });
  }, [searchValue, optionsList]);

  useEffect(() => {
    let on = true;

    setIsLoadingOptionsList(true);
    const fn = () => {
      getStockMachineOptionsList(machineCode)
        .then(({ response }) => {
          if (on) {
            setOptionsList(response ?? []);
          }
        })
        .finally(() => {
          if (on) {
            setIsLoadingOptionsList(false);
          }
        });
    };

    const timeoutHandle = setTimeout(fn, 500);
    return () => {
      clearTimeout(timeoutHandle);
      on = false;
    };
  }, [getStockMachineOptionsList, machineCode]);

  useEffect(() => {
    let on = true;

    setIsLoadingDetails(true);
    const fn = () => {
      getStockMachineDetails(machineCode)
        .then(({ response }) => {
          if (on) {
            setDetails(response || null);
          }
        })
        .finally(() => {
          if (on) {
            setIsLoadingDetails(false);
          }
        });
    };

    const timeoutHandle = setTimeout(fn, 500);
    return () => {
      clearTimeout(timeoutHandle);
      on = false;
    };
  }, [getStockMachineDetails, machineCode]);

  const isDisabled = true;

  const columns = useColumns();

  const schema = Yup.object();

  const formProps = useFormik({
    initialValues: details || {},
    onSubmit: () => {},
    validationSchema: schema,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,
  });

  return (
    <Modal variant="xl" visible={true} onCancel={onClose} center>
      {/* <ModalHeader $textAlign="center">
        {t('PRICE_LIST_STOCK_OPTIONS_MODAL__TITLE')}
      </ModalHeader> */}

      <ScModalContent>
        {(isLoadingDetails || isLoadingOptionsList) && (
          <ScRotatingLoaderWrap>
            <RotatingLoader />
          </ScRotatingLoaderWrap>
        )}

        <Row gutter={[15, 0]}>
          <Col span={12}>
            <h3>{t('PRICE_LIST_STOCK_OPTIONS_MODAL_DETAILS_TITLE')}</h3>

            <FormikProvider value={formProps}>
              <Form>
                <Row gutter={[15, 0]}>
                  <Col span={12}>
                    <FormField
                      name={StockMachineDetailsFields.brandName}
                      component={Input}
                      required={isFieldRequired(
                        schema,
                        StockMachineDetailsFields.brandName,
                      )}
                      disabled={isDisabled}
                      label={t('PRICE_LIST_STOCK_OPTIONS_MODAL_DETAILS_BRAND_NAME')}
                    />
                  </Col>
                  <Col span={12}>
                    <FormField
                      name={StockMachineDetailsFields.brandName}
                      component={Input}
                      required={isFieldRequired(
                        schema,
                        StockMachineDetailsFields.brandName,
                      )}
                      disabled={isDisabled}
                      label={t('PRICE_LIST_STOCK_OPTIONS_MODAL_DETAILS_BRAND_NAME')}
                    />
                  </Col>
                  <Col span={12}>
                    <FormField
                      name={StockMachineDetailsFields.modelName}
                      component={Input}
                      required={isFieldRequired(
                        schema,
                        StockMachineDetailsFields.modelName,
                      )}
                      disabled={isDisabled}
                      label={t('PRICE_LIST_STOCK_OPTIONS_MODAL_DETAILS_MODEL_NAME')}
                    />
                  </Col>
                  <Col span={12}>
                    <FormField
                      name={StockMachineDetailsFields.locationId}
                      component={Input}
                      required={isFieldRequired(
                        schema,
                        StockMachineDetailsFields.locationId,
                      )}
                      disabled={isDisabled}
                      label={t('PRICE_LIST_STOCK_OPTIONS_MODAL_DETAILS_WAREHOUSE')}
                    />
                  </Col>
                  <Col span={12}>
                    <FormField
                      name={StockMachineDetailsFields.modelNumber}
                      component={Input}
                      required={isFieldRequired(
                        schema,
                        StockMachineDetailsFields.modelNumber,
                      )}
                      disabled={isDisabled}
                      label={t('PRICE_LIST_STOCK_OPTIONS_MODAL_DETAILS_MODEL_NUMBER')}
                    />
                  </Col>
                  <Col span={12}>
                    <FormField
                      name={StockMachineDetailsFields.versionNumber}
                      component={Input}
                      required={isFieldRequired(
                        schema,
                        StockMachineDetailsFields.versionNumber,
                      )}
                      disabled={isDisabled}
                      label={t('PRICE_LIST_STOCK_OPTIONS_MODAL_DETAILS_VERSION_NUMBER')}
                    />
                  </Col>
                  <Col span={12}>
                    <FormField
                      name={StockMachineDetailsFields.machineNumber}
                      component={Input}
                      required={isFieldRequired(
                        schema,
                        StockMachineDetailsFields.machineNumber,
                      )}
                      disabled={isDisabled}
                      label={t('PRICE_LIST_STOCK_OPTIONS_MODAL_DETAILS_MACHINE_NUMBER')}
                    />
                  </Col>
                  <Col span={12}>
                    <FormField
                      name={StockMachineDetailsFields.licenceNumber}
                      component={Input}
                      required={isFieldRequired(
                        schema,
                        StockMachineDetailsFields.licenceNumber,
                      )}
                      disabled={isDisabled}
                      label={t('PRICE_LIST_STOCK_OPTIONS_MODAL_DETAILS_LICENCE_PLATE')}
                    />
                  </Col>
                  <Col span={12}>
                    <FormField
                      name={StockMachineDetailsFields.firstRegistrationAt}
                      component={DatePicker}
                      format={DATE_FORMAT}
                      required={isFieldRequired(
                        schema,
                        StockMachineDetailsFields.firstRegistrationAt,
                      )}
                      disabled={isDisabled}
                      label={t(
                        'PRICE_LIST_STOCK_OPTIONS_MODAL_DETAILS_FIRST_REGISTRATION_AT',
                      )}
                    />
                  </Col>
                  <Col span={12}>
                    <FormField
                      name={StockMachineDetailsFields.lastRegistrationAt}
                      component={DatePicker}
                      format={DATE_FORMAT}
                      required={isFieldRequired(
                        schema,
                        StockMachineDetailsFields.lastRegistrationAt,
                      )}
                      disabled={isDisabled}
                      label={t(
                        'PRICE_LIST_STOCK_OPTIONS_MODAL_DETAILS_LAST_REGISTRATION_AT',
                      )}
                    />
                  </Col>
                  <Col span={12}>
                    <FormField
                      name={StockMachineDetailsFields.taxHorsePowerAmount}
                      component={InputNumber}
                      required={isFieldRequired(
                        schema,
                        StockMachineDetailsFields.taxHorsePowerAmount,
                      )}
                      disabled={isDisabled}
                      label={t('PRICE_LIST_STOCK_OPTIONS_MODAL_DETAILS_TAX_HP')}
                    />
                  </Col>
                  <Col span={12}>
                    <FormField
                      name={StockMachineDetailsFields.gearboxCode}
                      component={Input}
                      required={isFieldRequired(
                        schema,
                        StockMachineDetailsFields.gearboxCode,
                      )}
                      disabled={isDisabled}
                      label={t('PRICE_LIST_STOCK_OPTIONS_MODAL_DETAILS_GEARBOX')}
                    />
                  </Col>
                  <Col span={12}>
                    <FormField
                      name={StockMachineDetailsFields.constructedAtYear}
                      component={DatePicker}
                      format={'YYYY'}
                      required={isFieldRequired(
                        schema,
                        StockMachineDetailsFields.constructedAtYear,
                      )}
                      disabled={isDisabled}
                      label={t(
                        'PRICE_LIST_STOCK_OPTIONS_MODAL_DETAILS_CONSTRUCTED_AT_YEAR',
                      )}
                    />
                  </Col>
                  <Col span={12}>
                    <FormField
                      name={StockMachineDetailsFields.customerNumber}
                      component={Input}
                      required={isFieldRequired(
                        schema,
                        StockMachineDetailsFields.customerNumber,
                      )}
                      disabled={isDisabled}
                      label={t('PRICE_LIST_STOCK_OPTIONS_MODAL_DETAILS_CUSTOMER_NUMBER')}
                    />
                  </Col>
                  <Col span={12}>
                    <FormField
                      name={StockMachineDetailsFields.customerNote}
                      component={Input}
                      required={isFieldRequired(
                        schema,
                        StockMachineDetailsFields.customerNote,
                      )}
                      disabled={isDisabled}
                      label={t('PRICE_LIST_STOCK_OPTIONS_MODAL_DETAILS_CUSTOMER_NOTE')}
                    />
                  </Col>
                  <Col span={12}>
                    <FormField
                      name={StockMachineDetailsFields.sellCode}
                      component={Input}
                      required={isFieldRequired(
                        schema,
                        StockMachineDetailsFields.sellCode,
                      )}
                      disabled={isDisabled}
                      label={t('PRICE_LIST_STOCK_OPTIONS_MODAL_DETAILS_SELL_CODE')}
                    />
                  </Col>
                  <Col span={12}>
                    <FormField
                      name={StockMachineDetailsFields.saleCode}
                      component={Input}
                      required={isFieldRequired(
                        schema,
                        StockMachineDetailsFields.saleCode,
                      )}
                      disabled={isDisabled}
                      label={t('PRICE_LIST_STOCK_OPTIONS_MODAL_DETAILS_SALE_CODE')}
                    />
                  </Col>
                  <Col span={12}>
                    <FormField
                      name={StockMachineDetailsFields.price}
                      component={InputNumber}
                      required={isFieldRequired(schema, StockMachineDetailsFields.price)}
                      disabled={isDisabled}
                      label={t('PRICE_LIST_STOCK_OPTIONS_MODAL_DETAILS_PRICE')}
                    />
                  </Col>
                  <Col span={12}>
                    <FormField
                      name={StockMachineDetailsFields.priceWithVat}
                      component={InputNumber}
                      required={isFieldRequired(
                        schema,
                        StockMachineDetailsFields.priceWithVat,
                      )}
                      disabled={isDisabled}
                      label={t('PRICE_LIST_STOCK_OPTIONS_MODAL_DETAILS_PRICE_WITH_VAT')}
                    />
                  </Col>
                </Row>
              </Form>
            </FormikProvider>
          </Col>

          <Col span={12}>
            <h3>{t('PRICE_LIST_STOCK_OPTIONS_MODAL_OPTIONS_TITLE')}</h3>

            <Input
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
              suffix={<SearchIcon />}
            />
            <Table
              rowKey={record => get(record, StockMachineOptionFields.ID)}
              columns={columns}
              data={preparedOptionsList}
              pageSize={17}
              scroll={{ x: 800 }}
              pagination
              showSizeChanger={false}
            />
          </Col>
        </Row>
      </ScModalContent>
    </Modal>
  );
};

export default StockOptionsModal;
