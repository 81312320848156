import styled from 'styled-components';
import Select from 'components/form/Selector';

export const ScModalContent = styled.div`
  position: relative;
  padding: 1em;
`;

export const ScSelect = styled(Select)`
  margin-bottom: 2em;
`;

export const ScModalContentText = styled.p`
  font-weight: 400;
`;

export const ScRotatingLoaderWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0.3;
  }
`;
